import { PaymentMode, selectErrors, UserRights } from "common";
import { InputError } from "components/Form";
import React from "react";
import { useSelector } from "react-redux";
import { selectProfileInfos } from "store/account/profile/profileSelectors";
import { FormattedMessage } from "react-intl";
export default function OrderMode({ setOrderMode, setCgu }) {
    const profile = useSelector(selectProfileInfos);
    const apiErrors = useSelector(selectErrors('ORDER'));
    return (React.createElement("div", { className: "k-orderstep--sub" },
        React.createElement("label", { className: "radio radio-before", htmlFor: "CB" },
            React.createElement("span", { className: "radio__input" },
                React.createElement("input", { name: "paymentMode", value: PaymentMode.CB, type: "radio", id: "CB", onClick: () => setOrderMode(PaymentMode.CB) }),
                React.createElement("span", { className: "radio__control" })),
            React.createElement("strong", null,
                React.createElement(FormattedMessage, { id: "Paiement par Carte bancaire", defaultMessage: "Paiement par Carte bancaire" }))),
        React.createElement("label", { className: "radio radio-before", htmlFor: "bridge" },
            React.createElement("span", { className: "radio__input" },
                React.createElement("input", { name: "paymentMode", value: PaymentMode.BRIDGE, type: "radio", id: "bridge", onClick: () => setOrderMode(PaymentMode.BRIDGE) }),
                React.createElement("span", { className: "radio__control" })),
            React.createElement("strong", { style: { width: '100%', lineHeight: '1.1em' } },
                React.createElement(FormattedMessage, { id: "Virement instantan\u00E9 via Bridge", defaultMessage: "Virement instantan\u00E9 via Bridge" }),
                React.createElement("small", { style: { fontWeight: 'normal', display: 'block' } },
                    React.createElement(FormattedMessage, { id: "Virement de banque \u00E0 banque", defaultMessage: "Virement de banque \u00E0 banque" })))),
        (profile === null || profile === void 0 ? void 0 : profile.type) === UserRights.PREMIUM &&
            React.createElement("label", { className: "radio radio-before", htmlFor: "virement" },
                React.createElement("span", { className: "radio__input" },
                    React.createElement("input", { name: "paymentMode", value: PaymentMode.TRANSFER, type: "radio", id: "virement", onClick: () => setOrderMode(PaymentMode.TRANSFER) }),
                    React.createElement("span", { className: "radio__control" })),
                React.createElement("strong", null,
                    React.createElement(FormattedMessage, { id: "Paiement par virement bancaire", defaultMessage: "Paiement par virement bancaire" }))),
        React.createElement("div", { className: "cgv" },
            React.createElement("br", null),
            React.createElement("label", { className: "is-checkbox radio radio-before", htmlFor: "cgv" },
                React.createElement("span", { className: "radio__input" },
                    React.createElement("input", { type: "checkbox", className: "checkbox", id: "cgv", onChange: setCgu }),
                    React.createElement("span", { className: "radio__control" })),
                React.createElement("strong", null,
                    React.createElement(FormattedMessage, { id: "En cochant cette case, je reconnais avoir pris connaissance des conditions g\u00E9n\u00E9rales de ventes", defaultMessage: 'En cochant cette case, je reconnais avoir pris connaissance des conditions g\u00E9n\u00E9rales de ventes', values: { a: chunks => React.createElement("a", null, chunks) } }),
                    "."))),
        React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.message })));
}
