import React from "react";
import { Angle } from "components/Angle/Angle";
import "./Step.scss";
export function Step({ disabled, children, title, description, step, activeStep, setActiveStep }) {
    const isStepActive = step === activeStep;
    return (React.createElement("div", { className: "k-orderstep" },
        React.createElement("div", { className: `k-orderstep--content ${isStepActive ? 'active' : ''}` },
            React.createElement("div", { className: "k-orderstep--trigger", onClick: () => setActiveStep(step) },
                React.createElement("span", { className: "k-orderstep--number" }, step),
                React.createElement("div", null,
                    React.createElement("strong", null, title),
                    React.createElement("p", null, description)),
                React.createElement(Angle, { disabled: disabled, isActive: isStepActive, setActiveStep: setActiveStep, step: step })),
            React.createElement("div", { className: "k-orderstep--spoiler" }, children))));
}
