import React from "react";
import "./CartInfos.scss";
import { Summary } from "./Summary";
import { useDispatch } from "react-redux";
import { removePromotionFromCart } from "store/cart/cartActions";
import { useIntl } from "react-intl";
export function CartInfos({ cart, children, title }) {
    const dispatch = useDispatch();
    const intl = useIntl();
    return (React.createElement("div", { className: "cart-infos" },
        React.createElement(Summary, { title: title ? title : intl.formatMessage({ id: 'Mon Panier', defaultMessage: 'Mon Panier' }), cart: cart, removePromotion: () => dispatch(removePromotionFromCart()) }, children)));
}
