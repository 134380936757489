import { Summary } from "components/Cart/Summary";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectCart } from "store/cart/cartSelectors";
import { AddressQuotation } from "./components/AddressQuotation";
import { useIntl } from "react-intl";
function Quotation() {
    const [quotationAddress, setQuotationAddress] = useState({
        quotationAddress: null,
    });
    const intl = useIntl();
    const setQuotationValue = (name, value) => {
        setQuotationAddress((prev) => (Object.assign(Object.assign({}, prev), { [name]: value })));
    };
    const cart = useSelector(selectCart);
    return (React.createElement("div", { className: "k-cart" },
        React.createElement(Helmet, null,
            React.createElement("title", null, "Devis - KUST | La Marque des Coiffeurs pas Comme Les Autres")),
        React.createElement("div", { className: "k-cart--breadcrumb" },
            React.createElement("ol", { className: "k-topbar--items" },
                React.createElement("li", { className: "k-topbar--item" },
                    React.createElement(Link, { className: "k-topbar--label", "data-text": "Accueil", to: "/" }, "Accueil")),
                React.createElement("li", { className: "k-topbar--item" },
                    React.createElement(Link, { className: "k-topbar--label", "data-text": "Produits", to: "/nos-produits" }, "Produits")),
                React.createElement("li", { className: "k-topbar--item" },
                    React.createElement(Link, { className: "k-topbar--label", "data-text": "Panier", to: "/panier" }, "Panier")),
                React.createElement("li", { className: "k-topbar--item" },
                    React.createElement(Link, { className: "k-topbar--label", "data-text": "Devis", to: "/devis" }, "Devis")))),
        React.createElement("div", { className: "k-cart--container" },
            React.createElement("section", { className: "k-cart--content" },
                React.createElement(AddressQuotation, { quotationValues: quotationAddress, setQuotationValue: setQuotationValue })),
            React.createElement("aside", { className: "k-cart--aside" },
                React.createElement(Summary, { title: intl.formatMessage({ id: "Mon récapitulatif", defaultMessage: "Mon récapitulatif" }), cart: cart })))));
}
export default Quotation;
