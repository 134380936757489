import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { fetchContentAttempt } from "store/contents/contentsActions";
import { selectContent } from "store/contents/contentsSelectors";
import "./Contents.scss";
import Img from 'components/Img/Img';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';
function Content() {
    var _a;
    const { slug } = useParams();
    const dispatch = useDispatch();
    const intl = useIntl();
    const content = useSelector(selectContent(slug));
    useEffect(() => {
        dispatch(fetchContentAttempt(slug));
    }, [dispatch, slug]);
    return (React.createElement("article", { className: "k-content" }, !!content && (React.createElement(React.Fragment, null,
        React.createElement(Helmet, null,
            React.createElement("title", null, content.title),
            content.metaDescription && React.createElement("meta", { name: "description", content: content.metaDescription }),
            content.metaKeywords && React.createElement("meta", { name: "keywords", content: content.metaKeywords })),
        (content === null || content === void 0 ? void 0 : content.file) &&
            React.createElement("header", { className: "container content-head k-content--banner" },
                React.createElement("h1", null,
                    React.createElement("span", null,
                        React.createElement(FormattedMessage, { id: content.title, defaultMessage: content.title })),
                    content.subtitle && React.createElement("i", null,
                        React.createElement(FormattedMessage, { id: content.subtitle, defaultMessage: content.subtitle }))),
                React.createElement("img", { src: content.file, alt: `Visuel pour ${content.title}` })),
        React.createElement("div", { className: "container content-body k-content--body" },
            !content.file &&
                React.createElement("header", null,
                    React.createElement("h1", null,
                        React.createElement("span", null,
                            React.createElement(FormattedMessage, { id: content.title, defaultMessage: content.title }))),
                    content.subtitle && React.createElement("i", null,
                        React.createElement(FormattedMessage, { id: content.subtitle, defaultMessage: content.subtitle }))),
            React.createElement("div", null,
                !!content.introTitle &&
                    React.createElement("section", null,
                        content.introTitle && React.createElement("h2", null,
                            React.createElement(FormattedMessage, { id: content.introTitle, defaultMessage: content.introTitle })),
                        React.createElement("div", { dangerouslySetInnerHTML: { __html: content.introContent } })), (_a = content.content) === null || _a === void 0 ? void 0 :
                _a.map((contentBlock, i) => React.createElement("section", { key: contentBlock === null || contentBlock === void 0 ? void 0 : contentBlock._id, className: 'k-content--section' },
                    React.createElement("div", { className: "k-content--section_data" },
                        contentBlock.title && React.createElement("h2", null,
                            React.createElement(FormattedMessage, { id: contentBlock.title, defaultMessage: contentBlock.title })),
                        React.createElement("div", { dangerouslySetInnerHTML: { __html: contentBlock.content } })),
                    contentBlock.file !== undefined
                        ? React.createElement("div", { className: "k-content--section_viz" },
                            React.createElement(Img, { src: `${contentBlock === null || contentBlock === void 0 ? void 0 : contentBlock.file}`, name: `${contentBlock.title}` }))
                        : null))),
            !!content.buttonLink && React.createElement(Link, { className: "k-content--link", to: content.buttonLink },
                React.createElement("button", { className: "k-cta k-cta--polygonal" },
                    React.createElement(FormattedMessage, { id: content.buttonTitle, defaultMessage: content.buttonTitle }))))))));
}
export default Content;
