import { selectErrors, selectPending } from "common";
import { StepButton } from "containers/Order/components/StepButton";
import React from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import useStep from "shared/hooks/useStep";
import { createAddressAttempt } from "store/account/addresses/addressActions";
import { selectAddresses, selectProfileInfos } from "store/account/profile/profileSelectors";
import { selectCart } from "store/cart/cartSelectors";
import { quotationAttempt } from "store/quotation/quotationActions";
import { Address } from "./Address";
import { CreateAddress } from "./CreateAddress";
export var Steps;
(function (Steps) {
    Steps[Steps["QUOTATION_ADDRESS_STEP"] = 1] = "QUOTATION_ADDRESS_STEP";
})(Steps || (Steps = {}));
var AddressTypes;
(function (AddressTypes) {
    AddressTypes["QUOTATION"] = "quotationAddress";
})(AddressTypes || (AddressTypes = {}));
export function AddressQuotation({ quotationValues, setQuotationValue }) {
    const dispatch = useDispatch();
    const intl = useIntl();
    const addresses = useSelector(selectAddresses);
    const profile = useSelector(selectProfileInfos);
    const selectQuotationAddress = (address) => {
        const addressId = (address === null || address === void 0 ? void 0 : address._id) || '';
        if (activeQuotationStep !== addressId)
            setActiveQuotationStep(addressId);
        setQuotationValue(AddressTypes.QUOTATION, address);
    };
    const [activeQuotationStep, setActiveQuotationStep] = useStep();
    const pending = useSelector(selectPending('ORDER'));
    const cart = useSelector(selectCart);
    const [activeStep, setActiveStep] = useStep(Steps.QUOTATION_ADDRESS_STEP);
    const createAddressErrors = useSelector(selectErrors('CREATE_ADDRESS'));
    const createAddressPending = useSelector(selectPending('CREATE_ADDRESS'));
    const callCreateAddress = (address, addressType) => {
        dispatch(createAddressAttempt(address, (err, res) => {
            if (res) {
                setActiveQuotationStep(res._id);
                setQuotationValue(addressType, res);
            }
        }));
    };
    const quotation = () => {
        dispatch(quotationAttempt(Object.assign(Object.assign(Object.assign({}, quotationValues), cart), { businessType: profile === null || profile === void 0 ? void 0 : profile.businessType })));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Address, { name: intl.formatMessage({ id: "Adresse du devis", defaultMessage: "Adresse du devis" }), title: "Informations", disableCurrentStep: false, activeQuotationStep: activeStep, setActiveQuotationStep: setActiveStep, step: Steps.QUOTATION_ADDRESS_STEP, addresses: addresses, activeStep: activeQuotationStep, selectAddress: selectQuotationAddress },
            React.createElement(CreateAddress, { name: AddressTypes.QUOTATION, activeStep: activeQuotationStep, selectAddress: selectQuotationAddress, createAddress: (address) => callCreateAddress(address, AddressTypes.QUOTATION), pending: createAddressPending, errors: createAddressErrors }),
            React.createElement(StepButton, { loading: false, disabled: pending, title: intl.formatMessage({ id: "Sauvegarder comme devis", defaultMessage: "Sauvegarder comme devis" }), onClick: quotation }))));
}
