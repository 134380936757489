import React, { useEffect, useState } from "react";
import "./Register.scss";
import "../../components/Form/Inputs/Input.scss";
import { useDispatch, useSelector } from "react-redux";
import { Input, Select, InputError, Button } from "components/Form";
import { selectAnswersAsSimpleArray } from "store/answers/answerSelectors";
import { fetchAnswersAttempt } from "store/answers/answerActions";
import { selectErrors, Countries, getCountryByCode, BusinessType, getBusinessTypeName } from "common";
import { Formik, Form } from "formik";
import { registerProfileAttempt } from "store/account/profile/profileActions";
import { removeEmptyString } from "shared/helpers";
import Socials from "containers/Socials/Socials";
import { selectSocials } from "store/account/profile/profileSelectors";
import { FormattedMessage, useIntl } from "react-intl";
function Register() {
    const dispatch = useDispatch();
    const intl = useIntl();
    const apiErrors = useSelector(selectErrors('REGISTER_PROFILE'));
    const socials = useSelector(selectSocials);
    const answers = useSelector(selectAnswersAsSimpleArray);
    useEffect(() => {
        dispatch(fetchAnswersAttempt());
    }, []);
    const onSubmit = (credentials) => {
        dispatch(registerProfileAttempt(removeEmptyString(credentials)));
    };
    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = (e) => {
        e.preventDefault();
        setShowPassword(!showPassword);
    };
    return (React.createElement("div", { className: "register" },
        React.createElement("div", null,
            React.createElement("small", { className: "k-t--label" },
                React.createElement(FormattedMessage, { id: "Inscription rapide", defaultMessage: "Inscription rapide" })),
            React.createElement(Socials, null)),
        React.createElement(Formik, { validateOnChange: true, initialValues: {
                lastname: !!socials ? socials.lastname : '',
                firstname: !!socials ? socials.firstname : '',
                salon: '',
                email: !!socials ? socials.email : '',
                email_confirmation: !!socials ? socials.email : '',
                phone: '',
                password: '',
                password_confirmation: '',
                country: Countries.FRANCE,
                siret: '',
                intraCommunityVATNumber: '',
                answers: [],
                businessType: BusinessType.B2B,
            }, enableReinitialize: true, onSubmit: onSubmit }, ({ values }) => (React.createElement(Form, { className: "k-form" },
            React.createElement(Input, { name: "lastname", label: intl.formatMessage({ id: 'Nom', defaultMessage: 'Nom' }), color: "white", placeholder: "Votre nom de famille" }),
            React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.lastname }),
            React.createElement(Input, { name: "firstname", label: intl.formatMessage({ id: 'Prénom', defaultMessage: 'Prénom' }), color: "white", placeholder: "Votre pr\u00E9nom" }),
            React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.firstname }),
            React.createElement(Select, { label: intl.formatMessage({ id: "Type de client", defaultMessage: "Type de client" }), defaultLabel: intl.formatMessage({ id: "Veuillez sélectionner votre type", defaultMessage: "Veuillez sélectionner votre type" }), name: "businessType", displayKey: "name", className: "k-input", valueKey: "value", options: Object.values(BusinessType)
                    .map(type => {
                    return { name: getBusinessTypeName(type), value: type };
                }) }),
            React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.businessType }),
            values.businessType === BusinessType.B2B &&
                React.createElement(React.Fragment, null,
                    React.createElement(Input, { name: "salon", label: intl.formatMessage({ id: 'Nom du salon', defaultMessage: 'Nom du salon' }), placeholder: "Le nom de votre salon", color: "grey" }),
                    React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.salon })),
            React.createElement(Input, { name: "email", label: intl.formatMessage({ id: 'E-mail', defaultMessage: 'E-mail' }), color: "grey", placeholder: "Votre adresse e-mail" }),
            React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.email }),
            React.createElement(Input, { name: "email_confirmation", label: intl.formatMessage({ id: 'Confirmation E-mail', defaultMessage: 'Confirmation E-mail' }), placeholder: "Adresse e-mail \u00E0 nouveau", color: "grey" }),
            React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.email_confirmation }),
            React.createElement(Input, { name: "phone", label: intl.formatMessage({ id: 'Téléphone', defaultMessage: 'Téléphone' }), color: "grey", placeholder: "Votre num\u00E9ro de t\u00E9l\u00E9phone" }),
            React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.phone }),
            React.createElement("div", { className: "k-form--password" },
                React.createElement(Input, { name: "password", type: showPassword ? "text" : "password", label: intl.formatMessage({ id: 'Mot de passe', defaultMessage: 'Mot de passe' }), color: "grey", placeholder: "Votre mot de passe \u00E0 nouveau" }),
                React.createElement(Button, { className: "active", name: "voir mot de passe", value: "0", onClick: togglePasswordVisibility }),
                React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.password }),
                React.createElement("ul", { className: "k-form--password_help" },
                    React.createElement("li", null,
                        React.createElement(FormattedMessage, { id: "8 caract\u00E8res minimum", defaultMessage: "8 caract\u00E8res minimum" })),
                    React.createElement("li", null,
                        React.createElement(FormattedMessage, { id: "1 majuscule et 1 minuscule", defaultMessage: "1 majuscule et 1 minuscule" })),
                    React.createElement("li", null,
                        React.createElement(FormattedMessage, { id: "1 chiffre minimum", defaultMessage: "1 chiffre minimum" })))),
            React.createElement("div", { className: "k-form--password" },
                React.createElement(Input, { name: "password_confirmation", type: showPassword ? "text" : "password", label: intl.formatMessage({ id: 'Confirmation du mot de passe', defaultMessage: 'Confirmation du mot de passe' }), color: "grey", placeholder: "Votre mot de passe \u00E0 nouveau" }),
                React.createElement(Button, { className: "active", name: "voir mot de passe", value: "0", onClick: togglePasswordVisibility }),
                React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.password_confirmation })),
            React.createElement(Select, { label: intl.formatMessage({ id: "Pays", defaultMessage: "Pays" }), defaultLabel: intl.formatMessage({ id: "Veuillez sélectionner un pays", defaultMessage: "Veuillez sélectionner un pays" }), name: "country", displayKey: "name", className: "k-input", valueKey: "value", options: Object.values(Countries)
                    .map(country => {
                    return { name: getCountryByCode(country).toUpperCase(), value: country };
                }) }),
            React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.country }),
            values.businessType === BusinessType.B2B &&
                React.createElement(React.Fragment, null, values.country === Countries.FRANCE ?
                    React.createElement(React.Fragment, null,
                        React.createElement(Input, { name: "siret", label: intl.formatMessage({ id: 'Numéro de siret', defaultMessage: 'Numéro de siret' }), placeholder: "Les chiffres de votre SIRET", color: "grey" }),
                        React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.siret }))
                    :
                        React.createElement(React.Fragment, null,
                            React.createElement(Input, { name: "intraCommunityVATNumber", label: intl.formatMessage({ id: "Numéro de TVA intracommunautaire (Si votre numéro est valide vous serez automatiquement exonéré de TVA)", defaultMessage: "Numéro de TVA intracommunautaire (Si votre numéro est valide vous serez automatiquement exonéré de TVA)" }), color: "grey" }),
                            React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.intraCommunityVATNumber }))),
            React.createElement(Select, { label: intl.formatMessage({ id: "Où avez-vous connu la marque KUST ?", defaultMessage: "Où avez-vous connu la marque KUST ?" }), defaultLabel: intl.formatMessage({ id: "Veuillez sélectionner une réponse", defaultMessage: "Veuillez sélectionner une réponse" }), valueKey: "label", displayKey: "label", name: "answers", options: answers, className: "k-input" }),
            React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.question }),
            React.createElement(Button, { buttonName: intl.formatMessage({ id: "Enregistrer", defaultMessage: "Enregistrer" }), className: "k-btn" }))))));
}
export default Register;
