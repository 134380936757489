import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchHomeAttempt } from "store/home/homeActions";
import { selectHome } from "store/home/homeSelectors";
import { Link } from "react-router-dom";
import ProductListItem from "containers/Products/components/ProductListItem";
import { selectRealisations } from "store/realisations/realisationsSelectors";
import { fetchRealisationsAttempt } from "store/realisations/realisationsActions";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import "./Home.scss";
import { selectTags } from "store/tags/tagSelectors";
import { fetchTagsAttempt } from "store/tags/tagActions";
function Home() {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const dispatch = useDispatch();
    const intl = useIntl();
    const home = useSelector(selectHome);
    const realisations = useSelector(selectRealisations);
    const tags = useSelector(selectTags);
    useEffect(() => {
        var _a, _b, _c;
        dispatch(fetchHomeAttempt());
        dispatch(fetchRealisationsAttempt());
        dispatch(fetchTagsAttempt());
        try {
            if (typeof ((_a = window) === null || _a === void 0 ? void 0 : _a.yotpo) !== "undefined") {
                (_c = (_b = window) === null || _b === void 0 ? void 0 : _b.yotpo) === null || _c === void 0 ? void 0 : _c.initWidgets();
            }
        }
        catch (err) {
            console.log('initWidget - error', err);
        }
    }, [dispatch]);
    const MIN_REALISATIONS = 18;
    const completeRealisations = Array.from({ length: MIN_REALISATIONS }, (_, index) => realisations[index % realisations.length]);
    const API_URL = ((_b = (_a = window) === null || _a === void 0 ? void 0 : _a.__env__) === null || _b === void 0 ? void 0 : _b.RAZZLE_API_URL);
    return (React.createElement("div", { className: "k-home" },
        React.createElement(Helmet, null,
            React.createElement("title", null, "KUST | La Marque des Coiffeurs pas Comme Les Autres")),
        React.createElement("section", { className: "k-home--hero" },
            React.createElement("div", { className: "k-home--hero_data" },
                !!home.title && React.createElement("h1", null,
                    !!home.pretitle && React.createElement("i", null,
                        React.createElement(FormattedMessage, { id: home === null || home === void 0 ? void 0 : home.pretitle, defaultMessage: home.pretitle })),
                    React.createElement(FormattedMessage, { id: home === null || home === void 0 ? void 0 : home.title, defaultMessage: home.title })),
                React.createElement("p", null, home.subtitle),
                !!home.buttonLink && !!home.buttonContent &&
                    React.createElement(Link, { to: home.buttonLink, className: "k-cta k-cta--polygonal" }, home.buttonContent)),
            React.createElement("img", { className: "k-home--hero_viz", src: home.file, alt: home.pretitle })),
        home.tagHome1 &&
            React.createElement(React.Fragment, null,
                React.createElement("section", { className: "k-highlight k-highlight--right", "data-color": home.tagColor1 },
                    React.createElement("header", { className: "k-highlight--header" },
                        React.createElement("h2", null,
                            React.createElement("span", null,
                                React.createElement(FormattedMessage, { id: "tagTitle1", defaultMessage: home.tagTitle1 })),
                            React.createElement("i", null,
                                React.createElement(FormattedMessage, { id: "tagSubTitle1", defaultMessage: home.tagSubTitle1 }))),
                        React.createElement("p", null,
                            React.createElement(FormattedMessage, { id: "tagDescription1", defaultMessage: home.tagDescription1 }))),
                    React.createElement("div", { className: "k-highlight--items" }, (_d = (_c = home.tagHome1) === null || _c === void 0 ? void 0 : _c.products) === null || _d === void 0 ? void 0 : _d.slice(0, 3).map((product) => React.createElement("article", { className: "k-highlight--item", key: product === null || product === void 0 ? void 0 : product._id },
                        React.createElement(ProductListItem, { path: `/nos-produits/${product.slug}`, product: Object.assign(Object.assign({}, product), { imageUrl: [(API_URL + '/' + product.imageUrl).replace('api/storage', 'storage')] }) })))),
                    React.createElement("figure", { className: "k-highlight--viz" }, API_URL &&
                        React.createElement("img", { src: (API_URL + '/' + ((_e = home === null || home === void 0 ? void 0 : home.tagHome1) === null || _e === void 0 ? void 0 : _e.image)).replace('api/storage', 'storage'), alt: home.tagHome1.name }))),
                React.createElement("section", { className: "k-highlight k-highlight--left", "data-color": home.tagColor2 },
                    React.createElement("header", { className: "k-highlight--header" },
                        React.createElement("h2", null,
                            React.createElement("span", null,
                                React.createElement(FormattedMessage, { id: "tagTitle2", defaultMessage: home.tagTitle2 })),
                            React.createElement("i", null,
                                React.createElement(FormattedMessage, { id: "tagSubTitle2", defaultMessage: home.tagSubTitle2 }))),
                        React.createElement("p", null,
                            React.createElement(FormattedMessage, { id: "tagDescription2", defaultMessage: home.tagDescription2 }))),
                    React.createElement("div", { className: "k-highlight--items" }, (_g = (_f = home.tagHome2) === null || _f === void 0 ? void 0 : _f.products) === null || _g === void 0 ? void 0 : _g.slice(0, 3).map((product) => React.createElement("article", { className: "k-highlight--item", key: product === null || product === void 0 ? void 0 : product._id },
                        React.createElement(ProductListItem, { path: `/nos-produits/${product.slug}`, product: Object.assign(Object.assign({}, product), { imageUrl: [(API_URL + '/' + product.imageUrl).replace('api/storage', 'storage')] }) })))),
                    React.createElement("figure", { className: "k-highlight--viz" }, API_URL &&
                        React.createElement("img", { src: (API_URL + '/' + ((_h = home === null || home === void 0 ? void 0 : home.tagHome2) === null || _h === void 0 ? void 0 : _h.image)).replace('api/storage', 'storage'), alt: home.tagHome2.name })))),
        realisations.length > 0 &&
            React.createElement("div", { className: "k-home--wall" },
                React.createElement("section", { className: "k-home--wall_grid" }, completeRealisations.map((realisation, idx) => (React.createElement(Fragment, { key: idx }, idx === 6 ? (React.createElement("header", { className: "k-home--wall_item" },
                    React.createElement("h3", null,
                        React.createElement(FormattedMessage, { id: "Kust Community", defaultMessage: "Kust Community" })),
                    React.createElement("p", null,
                        React.createElement(FormattedMessage, { id: "Les coiffeurs professionnels et leurs r\u00E9alisations avec nos produits de coloration, patines vibrantes, shampoings soin post-coloration...", defaultMessage: "Les coiffeurs professionnels et leurs r\u00E9alisations avec nos produits de coloration, patines vibrantes, shampoings soin post-coloration..." })))) : (React.createElement("a", { key: idx, href: `${realisation === null || realisation === void 0 ? void 0 : realisation.link}`, target: "_blank", className: "k-home--wall_item" }, idx % 4 !== 0 ? (React.createElement("figure", null,
                    React.createElement("img", { src: realisation === null || realisation === void 0 ? void 0 : realisation.file, alt: `Réalisation ${idx} avec produits pour coiffeurs professionnels` }))) : (React.createElement("blockquote", null,
                    React.createElement("p", null,
                        React.createElement("span", null,
                            React.createElement(FormattedMessage, { id: realisation === null || realisation === void 0 ? void 0 : realisation.review, defaultMessage: realisation === null || realisation === void 0 ? void 0 : realisation.review }))))))))))))));
}
export default Home;
