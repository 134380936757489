import React, { useState } from "react";
import "./Profile.scss";
import "../../../components/Form/Inputs/Input.scss";
import { useDispatch, useSelector } from "react-redux";
import { InputError, Input, Button, Select } from "components/Form";
import { removeEmptyString } from "shared/helpers";
import { selectErrors, Countries, selectPending, BusinessType } from "common";
import { Formik, Form } from "formik";
import { updateProfileAttempt } from "store/account/profile/profileActions";
import { selectProfileInfos, selectProfileUpdated } from "store/account/profile/profileSelectors";
import InputDate from "components/Form/Inputs/InputDate";
import { CheckMarkAnimation } from "components/Animations/CheckMarkAnimation";
import { FormattedMessage, useIntl } from "react-intl";
function Profile() {
    const dispatch = useDispatch();
    const intl = useIntl();
    const apiErrors = useSelector(selectErrors('UPDATE_PROFILE'));
    const pending = useSelector(selectPending('UPDATE_PROFILE'));
    const profileUpdated = useSelector(selectProfileUpdated);
    const profile = useSelector(selectProfileInfos);
    const onSubmit = (values) => {
        dispatch(updateProfileAttempt(removeEmptyString(values)));
    };
    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = (e) => {
        e.preventDefault();
        setShowPassword(!showPassword);
    };
    return (React.createElement("section", { className: "k-account--section" },
        React.createElement("header", null,
            React.createElement("h1", null,
                React.createElement(FormattedMessage, { id: "Vos informations", defaultMessage: "Vos informations" })),
            React.createElement("p", null,
                React.createElement(FormattedMessage, { id: "Modifiez vos informations ou acc\u00E8s \u00E0 votre profil en enregistrant les champs suivants :", defaultMessage: "Modifiez vos informations ou acc\u00E8s \u00E0 votre profil en enregistrant les champs suivants :" }))),
        React.createElement(Formik, { validateOnChange: true, initialValues: {
                lastname: (profile === null || profile === void 0 ? void 0 : profile.lastname) || '',
                firstname: (profile === null || profile === void 0 ? void 0 : profile.firstname) || '',
                salon: (profile === null || profile === void 0 ? void 0 : profile.salon) || '',
                email: profile === null || profile === void 0 ? void 0 : profile.email,
                email_confirmation: (profile === null || profile === void 0 ? void 0 : profile.email) || '',
                siret: profile === null || profile === void 0 ? void 0 : profile.siret,
                intraCommunityVATNumber: profile === null || profile === void 0 ? void 0 : profile.intraCommunityVATNumber,
                country: profile === null || profile === void 0 ? void 0 : profile.country,
                phoneFix: profile === null || profile === void 0 ? void 0 : profile.phoneFix,
                phone: profile === null || profile === void 0 ? void 0 : profile.phone,
                gender: profile === null || profile === void 0 ? void 0 : profile.gender,
                birthday: profile === null || profile === void 0 ? void 0 : profile.birthday
            }, enableReinitialize: true, onSubmit: onSubmit }, ({ values }) => (React.createElement(Form, { noValidate: true, autoComplete: 'off', className: "k-form k-form--white" },
            React.createElement(Input, { name: "lastname", label: intl.formatMessage({ id: 'Nom', defaultMessage: 'Nom' }), placeholder: intl.formatMessage({ id: 'Nom', defaultMessage: 'Nom' }) }),
            React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.lastname }),
            React.createElement(Input, { name: "firstname", label: intl.formatMessage({ id: 'Prénom', defaultMessage: 'Prénom' }), placeholder: intl.formatMessage({ id: 'Prénom', defaultMessage: 'Prénom' }) }),
            React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.firstname }),
            React.createElement(Input, { name: "phone", label: intl.formatMessage({ id: 'Tél Mobile', defaultMessage: 'Tel. Mobile' }), placeholder: intl.formatMessage({ id: 'Tél Mobile', defaultMessage: 'Tel. Mobile' }) }),
            React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.phone }),
            React.createElement(Input, { name: "phoneFix", label: intl.formatMessage({ id: 'Tel Fixe', defaultMessage: 'Tel. Fixe' }), placeholder: intl.formatMessage({ id: 'Tel Fixe', defaultMessage: 'Tel. Fixe' }) }),
            React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.phoneFix }),
            React.createElement(InputDate, { name: "birthday", label: intl.formatMessage({ id: 'Anniversaire', defaultMessage: 'Anniversaire' }), placeholder: intl.formatMessage({ id: 'Anniversaire', defaultMessage: 'Anniversaire' }) }),
            React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.birthday }),
            React.createElement(Select, { label: intl.formatMessage({ id: "Sexe", defaultMessage: "Sexe" }), defaultLabel: intl.formatMessage({ id: "Ne préfère pas préciser", defaultMessage: "Ne préfère pas préciser" }), displayKey: "name", valueKey: "value", name: "gender", options: [
                    { name: 'Homme', value: 'homme' },
                    { name: 'Femme', value: 'femme' }
                ] }),
            values.businessType === BusinessType.B2B &&
                React.createElement(React.Fragment, null, values.country === Countries.FRANCE ?
                    React.createElement(React.Fragment, null,
                        React.createElement(Input, { name: "siret", label: intl.formatMessage({ id: 'Numéro de siret', defaultMessage: 'Numéro de siret' }), placeholder: intl.formatMessage({ id: 'Numéro de siret', defaultMessage: 'Numéro de siret' }), color: "grey", disabled: true }),
                        React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.siret }))
                    :
                        React.createElement(React.Fragment, null,
                            React.createElement(Input, { name: "intraCommunityVATNumber", label: intl.formatMessage({ id: "Numéro de TVA intracommunautaire", defaultMessage: "Numéro de TVA intracommunautaire" }), placeholder: intl.formatMessage({ id: "Numéro de TVA intracommunautaire", defaultMessage: "Numéro de TVA intracommunautaire" }), disabled: true, color: "grey" }),
                            React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.intraCommunityVATNumber }))),
            values.businessType === BusinessType.B2B &&
                React.createElement(React.Fragment, null,
                    React.createElement(Input, { name: "salon", label: intl.formatMessage({ id: 'Nom du salon', defaultMessage: 'Nom du salon' }), placeholder: intl.formatMessage({ id: 'Nom du salon', defaultMessage: 'Nom du salon' }) }),
                    React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.salon })),
            React.createElement(Input, { name: "email", label: intl.formatMessage({ id: 'E-mail', defaultMessage: 'E-mail' }), placeholder: intl.formatMessage({ id: 'E-mail', defaultMessage: 'E-mail' }) }),
            React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.email }),
            React.createElement(Input, { name: "email_confirmation", label: intl.formatMessage({ id: 'Confirmation email', defaultMessage: 'Confirmation email' }), placeholder: intl.formatMessage({ id: 'Confirmation email', defaultMessage: 'Confirmation email' }) }),
            React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.email_confirmation }),
            React.createElement("div", { className: "k-form--password" },
                React.createElement(Input, { type: showPassword ? "text" : "password", name: "password", label: intl.formatMessage({ id: 'Mot de passe', defaultMessage: 'Mot de passe' }), placeholder: intl.formatMessage({ id: 'Mot de passe', defaultMessage: 'Mot de passe' }) }),
                React.createElement(Button, { className: "active", name: "voir mot de passe", title: "Voir le mot de passe", value: "0", onClick: togglePasswordVisibility }),
                React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.password }),
                React.createElement("ul", { className: "k-form--password_help" },
                    React.createElement("li", null,
                        React.createElement(FormattedMessage, { id: "8 caract\u00E8res minimum", defaultMessage: "8 caract\u00E8res minimum" })),
                    React.createElement("li", null,
                        React.createElement(FormattedMessage, { id: "1 majuscule et 1 minuscule", defaultMessage: "1 majuscule et 1 minuscule" })),
                    React.createElement("li", null,
                        React.createElement(FormattedMessage, { id: "1 chiffre minimum", defaultMessage: "1 chiffre minimum" })))),
            React.createElement("div", { className: "k-form--password" },
                React.createElement(Input, { type: showPassword ? "text" : "password", name: "password_confirmation", label: intl.formatMessage({ id: 'Confirmation de mot de passe', defaultMessage: 'Confirmation de mot de passe' }), placeholder: intl.formatMessage({ id: 'Votre mot de passe à nouveau', defaultMessage: 'Votre mot de passe à nouveau' }) }),
                React.createElement(Button, { className: "active", name: "voir mot de passe", title: "Voir le mot de passe", value: "0", onClick: togglePasswordVisibility }),
                React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.password_confirmation })),
            React.createElement(Button, { loading: pending, buttonName: intl.formatMessage({ id: "Enregistrer", defaultMessage: "Enregistrer" }), className: "k-btn" })))),
        React.createElement(CheckMarkAnimation, { message: "La modification de vos donn\u00E9es a bien \u00E9t\u00E9 enregistr\u00E9e.", isActive: profileUpdated })));
}
export default Profile;
