import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./Footer.scss";
import { FormattedMessage } from "react-intl";
function Footer() {
    const { pathname } = useLocation();
    const getFooterClasses = () => {
        return pathname === '/' ? 'k-footer--white' : '';
    };
    function getYear() {
        return new Date().getFullYear();
    }
    return (React.createElement("footer", { className: `${getFooterClasses()} k-footer` },
        React.createElement("div", { className: "footer-logo" },
            React.createElement("img", { src: "/assets/logo-k.png" })),
        React.createElement("div", { className: "k-footer--container" },
            React.createElement("div", { className: "k-footer--icons" },
                React.createElement("div", { className: "k-footer--icons_item icon-lock" },
                    React.createElement("span", null,
                        React.createElement(FormattedMessage, { id: "Paiement s\u00E9curis\u00E9", defaultMessage: "Paiement s\u00E9curis\u00E9" }))),
                React.createElement("div", { className: "k-footer--icons_item icon-speed" },
                    React.createElement("span", null,
                        React.createElement(FormattedMessage, { id: "Livraison rapide", defaultMessage: "Livraison rapide" }))),
                React.createElement("div", { className: "k-footer--icons_item icon-at" },
                    React.createElement("span", null,
                        React.createElement(FormattedMessage, { id: "Disponible 24/7", defaultMessage: "Disponible 24/7" }))),
                React.createElement("div", { className: "k-footer--icons_item icon-like" },
                    React.createElement("span", null,
                        React.createElement(FormattedMessage, { id: "Rapport Qualit\u00E9/Prix", defaultMessage: "Rapport Qualit\u00E9/Prix" }))),
                React.createElement("div", { className: "k-footer--icons_item icon-heart" },
                    React.createElement("span", null,
                        React.createElement(FormattedMessage, { id: "Exclusif coiffeurs", defaultMessage: "Exclusif coiffeurs" })))),
            React.createElement("div", { className: "k-footer--links" },
                React.createElement("ul", null,
                    React.createElement("li", null,
                        React.createElement("strong", null,
                            React.createElement(FormattedMessage, { id: "Mon compte", defaultMessage: "Mon compte" }))),
                    React.createElement("li", null,
                        React.createElement(Link, { to: "/authentification" },
                            React.createElement(FormattedMessage, { id: "Connexion", defaultMessage: "Connexion" }))),
                    React.createElement("li", null,
                        React.createElement(Link, { to: "/mon-compte/profile" },
                            React.createElement(FormattedMessage, { id: "Mes Infos", defaultMessage: "Mes Infos" }))),
                    React.createElement("li", null,
                        React.createElement(Link, { to: "/mon-compte/commandes" },
                            React.createElement(FormattedMessage, { id: "Mes Commandes", defaultMessage: "Mes Commandes" }))),
                    React.createElement("li", null,
                        React.createElement(Link, { to: "/mon-compte/commandes" },
                            React.createElement(FormattedMessage, { id: "Mes Factures", defaultMessage: "Mes Factures" }))),
                    React.createElement("li", null,
                        React.createElement(Link, { to: "/mon-compte/documents" },
                            React.createElement(FormattedMessage, { id: "Mes Documents", defaultMessage: "Mes Documents" })))),
                React.createElement("ul", null,
                    React.createElement("li", null,
                        React.createElement("strong", null,
                            React.createElement(FormattedMessage, { id: "Colorations", defaultMessage: "Colorations" }))),
                    React.createElement("li", null,
                        React.createElement(Link, { to: "/pages/coloration-essential" },
                            React.createElement(FormattedMessage, { id: "La coloration ESSENTIAL", defaultMessage: "La coloration ESSENTIAL" }))),
                    React.createElement("li", null,
                        React.createElement(Link, { to: "/pages/coloration-pure" },
                            React.createElement(FormattedMessage, { id: "La coloration PURE", defaultMessage: "La coloration PURE" }))),
                    React.createElement("li", null,
                        React.createElement(Link, { to: "/pages/coloration-armonia" },
                            React.createElement(FormattedMessage, { id: "La coloration ARMONIA", defaultMessage: "La coloration ARMONIA" }))),
                    React.createElement("li", null,
                        React.createElement(Link, { to: "/pages/patine-kontrol" },
                            React.createElement(FormattedMessage, { id: "La coloration KONTROL", defaultMessage: "La coloration KONTROL" }))),
                    React.createElement("li", null,
                        React.createElement(Link, { to: "/nos-produits/kroma" },
                            React.createElement(FormattedMessage, { id: "La coloration KROMA", defaultMessage: "La coloration KROMA" })))),
                React.createElement("ul", null,
                    React.createElement("li", null,
                        React.createElement("strong", null,
                            React.createElement(FormattedMessage, { id: "La marque", defaultMessage: "La marque" }))),
                    React.createElement("li", null,
                        React.createElement(Link, { to: "/pages/a-propos" },
                            React.createElement(FormattedMessage, { id: "D\u00E9couvrir Kust", defaultMessage: "D\u00E9couvrir Kust" }))),
                    React.createElement("li", null,
                        React.createElement(Link, { to: "/pages/qui-peut-acheter-chez-kust" },
                            React.createElement(FormattedMessage, { id: "Exclusivit\u00E9 d'achat", defaultMessage: "Exclusivit\u00E9 d'achat" }))),
                    React.createElement("li", null,
                        React.createElement("a", { target: "_blank", href: "https://www.facebook.com/groups/kust.community/" },
                            React.createElement(FormattedMessage, { id: "La communaut\u00E9 Kust", defaultMessage: "La communaut\u00E9 Kust" }))),
                    React.createElement("li", null,
                        React.createElement(Link, { to: "/pages/partenariats" },
                            React.createElement(FormattedMessage, { id: "Partenariats", defaultMessage: "Partenariats" }))),
                    React.createElement("li", null,
                        React.createElement(Link, { to: "/avis" },
                            React.createElement(FormattedMessage, { id: "Avis clients", defaultMessage: "Avis clients" })))),
                React.createElement("ul", null,
                    React.createElement("li", null,
                        React.createElement("strong", null,
                            React.createElement(FormattedMessage, { id: "Informations", defaultMessage: "Informations" }))),
                    React.createElement("li", null,
                        React.createElement(Link, { to: "/pages/qui-sommes-nous" },
                            React.createElement(FormattedMessage, { id: "A propos", defaultMessage: "A propos" }))),
                    React.createElement("li", null,
                        React.createElement(Link, { to: "/pages/nous-contacter" },
                            React.createElement(FormattedMessage, { id: "Contact", defaultMessage: "Contact" }))),
                    React.createElement("li", null,
                        React.createElement(Link, { to: "/pages/mentions-legales" },
                            React.createElement(FormattedMessage, { id: "Mentions L\u00E9gales", defaultMessage: "Mentions L\u00E9gales" }))),
                    React.createElement("li", null,
                        React.createElement(Link, { to: "/pages/confidentialite" },
                            React.createElement(FormattedMessage, { id: "Confidentialit\u00E9", defaultMessage: "Confidentialit\u00E9" }))),
                    React.createElement("li", null,
                        React.createElement(Link, { to: "/pages/cgv" },
                            React.createElement(FormattedMessage, { id: "CGV", defaultMessage: "CGV" })))),
                React.createElement("ul", null,
                    React.createElement("li", null,
                        React.createElement("strong", null,
                            React.createElement(FormattedMessage, { id: "Communaut\u00E9", defaultMessage: "Communaut\u00E9" }))),
                    React.createElement("li", null,
                        React.createElement("a", { target: "_blank", href: "https://www.facebook.com/kustofficiel", className: "icon icon-facebook" }, "Facebook")),
                    React.createElement("li", null,
                        React.createElement("a", { target: "_blank", href: "https://www.instagram.com/kust_officiel/?hl=fr", className: "icon icon-instagram" }, "Instagram")),
                    React.createElement("li", null,
                        React.createElement("a", { target: "_blank", href: "https://www.tiktok.com/@kust_officiel?lang=fr", className: "icon icon-tiktok" }, "Tiktok")))),
            React.createElement("div", { className: "k-footer--credits" },
                React.createElement("span", null,
                    "\u00A9 ",
                    getYear()),
                React.createElement("span", null,
                    React.createElement(FormattedMessage, { id: "KUST marque d\u00E9pos\u00E9e", defaultMessage: "KUST marque d\u00E9pos\u00E9e" })),
                React.createElement("span", null,
                    React.createElement(FormattedMessage, { id: "Tous Droits R\u00E9serv\u00E9s", defaultMessage: "Tous Droits R\u00E9serv\u00E9s" })),
                React.createElement("span", null,
                    React.createElement("a", { href: "https://2pulse.com", target: "_blank" },
                        React.createElement(FormattedMessage, { id: "Cr\u00E9ation agence 2pulse", defaultMessage: "Cr\u00E9ation agence 2pulse" })))))));
}
export default Footer;
