import React from "react";
import { PudoListItem } from "./PudoListItem";
import { FormattedMessage } from "react-intl";
export function PudosList({ pudos }) {
    return (React.createElement("div", { style: {
            maxHeight: "300px",
            overflowY: "scroll",
            display: "flex",
            flexDirection: "column",
            gap: "21px"
        } },
        React.createElement("p", { style: { margin: "13px 0" } },
            React.createElement(FormattedMessage, { id: "Veuillez choisir votre point relais : ", defaultMessage: "Veuillez choisir votre point relais : " })),
        pudos.map(pudo => (React.createElement(PudoListItem, { key: pudo._id, pudo: pudo })))));
}
