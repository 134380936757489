import { ProductType, convertToEuros, getRangeFromQuantity, isB2B, isChoiceStockValid, selectIsAuthenticated } from "common";
import Img from "components/Img/Img";
import { FormattedMessage } from "components/Intl/FormattedMessage";
import { Decrement, Increment } from "components/Quantity";
import React from "react";
import { useSelector } from "react-redux";
import { selectProfileInfos } from "store/account/profile/profileSelectors";
export function ProductChoiceItem({ increment, decrement, cartProduct, choice, onChange }) {
    var _a, _b;
    const quantity = choice.quantity || 1;
    const profile = useSelector(selectProfileInfos);
    const priceKey = isB2B(profile) ? 'price' : 'b2cPriceVat';
    const priceInCents = (_b = (_a = getRangeFromQuantity(choice.priceRanges, quantity)) === null || _a === void 0 ? void 0 : _a[priceKey]) !== null && _b !== void 0 ? _b : cartProduct[priceKey];
    const price = priceInCents ? convertToEuros(priceInCents) : 0;
    const isAuthenticated = useSelector(selectIsAuthenticated());
    const onKeyDown = (keyEvent) => {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    };
    return (React.createElement("div", { className: `k-subproduct choice-item ${isChoiceStockValid(choice) ? '' : 'disabled'} ${choice.quantity > 0 ? "active" : ""}` },
        React.createElement("div", { onClick: isChoiceStockValid(choice) ? increment : undefined },
            React.createElement("div", { className: "k-subproduct--viz" },
                React.createElement(Img, { src: `${choice.imageUrl}`, width: 100, height: 100, name: `${choice.name}` })),
            React.createElement("dl", { className: "k-subproduct--data" },
                React.createElement("dt", null,
                    React.createElement(FormattedMessage, { id: choice.name, defaultMessage: choice.name })),
                React.createElement("dd", null,
                    React.createElement(FormattedMessage, { id: choice.description, defaultMessage: choice.description })))),
        choice.quantity > 0 && React.createElement("div", { className: "k-subproduct--selection k-quantity" },
            React.createElement(Decrement, { decrement: decrement }),
            React.createElement("input", { type: "number", value: choice.quantity, onChange: onChange, onKeyDown: onKeyDown, min: 0 }),
            React.createElement(Increment, { increment: isChoiceStockValid(choice) ? increment : undefined })),
        (isAuthenticated && cartProduct.type === ProductType.CHOICE_PRODUCT) && (React.createElement("small", { className: "k-subproduct--price" },
            price,
            "\u20AC / unit\u00E9",
            React.createElement("br", null)))));
}
