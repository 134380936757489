var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Field } from "formik";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import "./Input.scss";
function Input(_a) {
    var { type = 'text', name, label, color, rules, placeholder } = _a, rest = __rest(_a, ["type", "name", "label", "color", "rules", "placeholder"]);
    const intl = useIntl();
    return (React.createElement(Field, { name: name }, ({ form, field }) => (React.createElement("label", Object.assign({ className: "k-input input-label" }, rest),
        !!label &&
            React.createElement("span", { className: "k-input--label" },
                React.createElement(FormattedMessage, { id: label, defaultMessage: label })),
        React.createElement("input", Object.assign({ onChange: (e) => {
                form.setFieldValue(name, e.target.value);
            }, 
            // defaultValue={field.value}
            type: type, value: field.value, name: name, placeholder: placeholder, className: "k-input--input" }, rest))))));
}
export default Input;
