var _a, _b;
import { isBrowser, PaymentMode, selectPending } from "common";
import React, { useEffect, useState } from "react";
import { Step } from "./components/Step";
import { Steps } from "./OrderSteps";
import KRGlue from "@lyracom/embedded-form-glue";
import { useDispatch, useSelector } from "react-redux";
import { selectCart } from "store/cart/cartSelectors";
import { selectFormToken, selectPaiementLink } from "store/order/orderSelectors";
import { push } from "connected-react-router";
import { StepButton } from "./components/StepButton";
import OrderMode from "./components/OrderMode";
import { orderAttempt, orderSuccess } from "store/order/orderActions";
import { resetCart } from "store/cart/cartActions";
import { Modal } from "components/Modal/Modal";
import useModal from "shared/hooks/useModal";
import { selectProfileInfos } from "store/account/profile/profileSelectors";
import { FormattedMessage, useIntl } from "react-intl";
const RAZZLE_SYSTEMPAY_PUBLIC_KEY = isBrowser()
    ? `${(_a = window.__env__) === null || _a === void 0 ? void 0 : _a.RAZZLE_SYSTEMPAY_PUBLIC_KEY}`
    : `${process.env.RAZZLE_SYSTEMPAY_PUBLIC_KEY}`;
const RAZZLE_SYSTEMPAY_ENDPOINT = isBrowser()
    ? `${(_b = window.__env__) === null || _b === void 0 ? void 0 : _b.RAZZLE_SYSTEMPAY_ENDPOINT}`
    : `${process.env.RAZZLE_SYSTEMPAY_ENDPOINT}`;
export function PaymentStep({ orderValues, setOrderValue, activeOrderStep, setActiveOrderStep }) {
    const intl = useIntl();
    const MODAL_ID = 'payment_modal';
    const dispatch = useDispatch();
    const { isModalOpen, toggleModal } = useModal();
    const isModalOpened = isModalOpen(MODAL_ID);
    const cart = useSelector(selectCart);
    const pending = useSelector(selectPending('ORDER'));
    const formToken = useSelector(selectFormToken);
    const paiementLink = useSelector(selectPaiementLink);
    const profile = useSelector(selectProfileInfos);
    const [isPaymentFormLoading, setPaymentFormLoading] = useState(false);
    const [cgu, setCgu] = useState(false);
    const setOrderMode = (mode) => {
        setOrderValue('paymentMode', mode);
        if (orderValues.paymentMode !== mode && (mode === PaymentMode.CB || mode === PaymentMode.BRIDGE)) {
            dispatch(orderAttempt(Object.assign(Object.assign(Object.assign(Object.assign({}, orderValues), { paymentMode: mode }), cart), { businessType: profile === null || profile === void 0 ? void 0 : profile.businessType })));
        }
    };
    const order = () => {
        if (orderValues.paymentMode === PaymentMode.CB) {
            toggleModal(MODAL_ID);
        }
        else if (orderValues.paymentMode === PaymentMode.TRANSFER) {
            dispatch(orderAttempt(Object.assign(Object.assign(Object.assign({}, orderValues), cart), { businessType: profile === null || profile === void 0 ? void 0 : profile.businessType })));
        }
        else if (orderValues.paymentMode === PaymentMode.BRIDGE) {
            dispatch(orderSuccess(Object.assign(Object.assign(Object.assign({}, orderValues), cart), { businessType: profile === null || profile === void 0 ? void 0 : profile.businessType })));
            window.location.href = paiementLink;
        }
    };
    useEffect(() => {
        if (formToken && isModalOpened) {
            setPaymentFormLoading(true);
            KRGlue.loadLibrary(RAZZLE_SYSTEMPAY_ENDPOINT, RAZZLE_SYSTEMPAY_PUBLIC_KEY)
                .then(({ KR }) => {
                return KR === null || KR === void 0 ? void 0 : KR.setFormConfig({ formToken, "kr-language": "fr-FR" });
            })
                .then(({ KR }) => {
                return KR === null || KR === void 0 ? void 0 : KR.addForm("#payment__form");
            })
                .then(({ KR, result }) => {
                return KR === null || KR === void 0 ? void 0 : KR.showForm(result.formId);
            })
                .then(({ KR }) => {
                setPaymentFormLoading(false);
                return KR === null || KR === void 0 ? void 0 : KR.onSubmit(() => {
                    dispatch(resetCart());
                    dispatch(push(`/commande/succes`));
                    return true;
                });
            })
                .catch((err) => {
                setPaymentFormLoading(false);
                dispatch(push(`/commande/echec`));
                console.log(err);
            });
        }
    }, [dispatch, formToken, paiementLink, isModalOpened]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Step, { step: Steps.PAYMENT_STEP, disabled: !orderValues.shippingAddress, activeStep: activeOrderStep, setActiveStep: setActiveOrderStep, title: intl.formatMessage({ id: "Paiement", defaultMessage: "Paiement" }), description: intl.formatMessage({ id: "Regler votre commande avec le moyen de paiement souhaité.", defaultMessage: "Regler votre commande avec le moyen de paiement souhaité." }) },
            React.createElement("div", { className: "k-cart--fixed" },
                React.createElement(StepButton, { loading: false, order: true, disabled: pending || !(orderValues.paymentMode in PaymentMode) || !cgu, title: intl.formatMessage({ id: "Régler ma commande", defaultMessage: "Régler ma commande" }), onClick: order })),
            React.createElement("div", null,
                React.createElement(OrderMode, { setOrderMode: setOrderMode, setCgu: () => {
                        setCgu(!cgu);
                    } }),
                React.createElement(Modal, { id: MODAL_ID },
                    React.createElement("div", { style: { display: (isModalOpened && !isPaymentFormLoading) ? 'block' : 'none' }, className: "payment-modal" },
                        React.createElement("div", { className: "form k-modal--content" },
                            React.createElement("strong", null, "R\u00E8glement s\u00E9curis\u00E9"),
                            React.createElement("div", { className: "container" }),
                            React.createElement("div", { id: "payment__form" }))),
                    React.createElement("div", { style: { display: (isModalOpened && isPaymentFormLoading) ? 'block' : 'none' }, className: "payment-modal" },
                        React.createElement("p", null,
                            React.createElement(FormattedMessage, { id: "CHARGEMENT", defaultMessage: "CHARGEMENT " }),
                            "..."))),
                activeOrderStep === Steps.PAYMENT_STEP &&
                    React.createElement(StepButton, { loading: false, disabled: pending || !(orderValues.paymentMode in PaymentMode) || !cgu, title: intl.formatMessage({ id: "Régler ma commande", defaultMessage: "Régler ma commande" }), onClick: order })))));
}
