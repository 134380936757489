import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProfileAttempt } from 'store/account/profile/profileActions';
import { selectTrainings } from 'store/account/profile/profileSelectors';
import "./Formations.scss";
import { FormattedMessage, useIntl } from "react-intl";
function Formations() {
    const dispatch = useDispatch();
    const intl = useIntl();
    const trainings = useSelector(selectTrainings);
    useEffect(() => {
        dispatch(fetchProfileAttempt());
    }, [dispatch]);
    return (React.createElement("section", { className: "k-account--section" },
        React.createElement("header", null,
            React.createElement("h1", null,
                React.createElement(FormattedMessage, { id: "Formations", defaultMessage: "Formations" })),
            (trainings === null || trainings === void 0 ? void 0 : trainings.length) === 0 ?
                React.createElement("p", null,
                    React.createElement(FormattedMessage, { id: "Il n'y a pas de contenu de formation disponible pour le moment", defaultMessage: "Il n'y a pas de contenu de formation disponible pour le moment" }),
                    ".")
                :
                    React.createElement("p", null,
                        React.createElement(FormattedMessage, { id: "Contenu de formation \u00E0 votre disposition", defaultMessage: "Contenu de formation \u00E0 votre disposition" }),
                        ".")),
        React.createElement("div", { className: "trainings container" }, (trainings === null || trainings === void 0 ? void 0 : trainings.length) > 0 &&
            React.createElement("div", null, trainings.reverse().map((training) => React.createElement("div", { className: "training-item", key: training === null || training === void 0 ? void 0 : training._id },
                React.createElement("div", null,
                    React.createElement("strong", null, training.name),
                    React.createElement("p", null,
                        training.address,
                        " ",
                        training.postalCode,
                        " ",
                        training.city),
                    React.createElement("p", null,
                        training.date,
                        ": ",
                        training.schedule),
                    training.secondDate && React.createElement("p", null,
                        training.secondDate,
                        ": ",
                        training.secondSchedule))))))));
}
export default Formations;
