import React, { useState } from "react";
import "./Login.scss";
import "../../components/Form/Inputs/Input.scss";
import { useDispatch, useSelector } from 'react-redux';
import { Input, Button, InputError } from "components/Form";
import { Link } from "react-router-dom";
import { loginAttempt, selectErrors } from "common";
import { Formik, Form } from "formik";
import useModal from "shared/hooks/useModal";
import Socials from "containers/Socials/Socials";
import { FormattedMessage, useIntl } from "react-intl";
function Login({ modalId }) {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { isModalOpen, closeModal } = useModal();
    const errors = useSelector(selectErrors('LOGIN'));
    console.log('errors', errors);
    const onSubmit = (credentials) => {
        dispatch(loginAttempt(credentials));
        closeModal(modalId);
    };
    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = (e) => {
        e.preventDefault();
        setShowPassword(!showPassword);
    };
    return (React.createElement("div", { className: "login" },
        React.createElement("div", null,
            React.createElement("small", { className: "k-t--label" },
                React.createElement(FormattedMessage, { id: "Connexion rapide", defaultMessage: "Connexion rapide" })),
            React.createElement(Socials, null)),
        React.createElement(Formik, { validateOnChange: true, initialValues: {
                email: '',
                password: '',
                remember_me: false
            }, enableReinitialize: true, onSubmit: onSubmit },
            React.createElement(Form, { className: "k-form" },
                React.createElement(Input, { name: "email", label: intl.formatMessage({ id: 'E-mail', defaultMessage: 'E-mail' }), placeholder: "Votre adresse e-mail", color: "white" }),
                React.createElement("div", { className: "k-form--password" },
                    React.createElement(Input, { name: "password", placeholder: "Votre mot de passe", type: showPassword ? "text" : "password", label: intl.formatMessage({ id: 'Mot de passe', defaultMessage: 'Mot de passe' }), color: "white" }),
                    React.createElement(Button, { className: "active", name: "voir mot de passe", value: "0", onClick: togglePasswordVisibility })),
                React.createElement(Button, { className: "k-btn", type: "submit", buttonName: intl.formatMessage({ id: "Connexion", defaultMessage: "Connexion" }) }),
                React.createElement(InputError, { apiErrors: errors === null || errors === void 0 ? void 0 : errors.login }),
                React.createElement(Link, { to: "/recuperation", className: "k-lk--icon icon-help" },
                    React.createElement(FormattedMessage, { id: "Mot de passe oubli\u00E9", defaultMessage: "Mot de passe oubli\u00E9" }))))));
}
/*
    A revoir, ce n'est pas dans le design
                <!--<label>Se souvenir de moi</label>
                <Input type="checkbox" name="remember_me" />-->

*/
export default Login;
