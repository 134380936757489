import { selectErrors, selectPending } from 'common';
import { Button } from 'components/Form';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createAddressAttempt, deleteAddressAttempt, updateAddressAttempt } from 'store/account/addresses/addressActions';
import "./Adresses.scss";
import { AddressForm } from 'components/AddressForm/AddressForm';
import { selectAddresses, selectAddressUpdated } from 'store/account/profile/profileSelectors';
import useStep from 'shared/hooks/useStep';
import { Angle } from 'components/Angle/Angle';
import { Formik } from 'formik';
import { CheckMarkAnimation } from 'components/Animations/CheckMarkAnimation';
import { FormattedMessage, useIntl } from "react-intl";
const initialValues = {
    name: '',
    firstname: '',
    lastname: '',
    salon: '',
    address: '',
    country: '',
    city: '',
    postalCode: '',
    phone: '',
    phoneFix: ''
};
function Adresses() {
    const dispatch = useDispatch();
    const intl = useIntl();
    const [activeStep, setActiveStep] = useStep();
    const addressUpdated = useSelector(selectAddressUpdated);
    const apiErrors = useSelector(activeStep === ''
        ? selectErrors('CREATE_ADDRESS')
        : selectErrors('UPDATE_ADDRESS'));
    const addresses = useSelector(selectAddresses);
    const pending = useSelector(selectPending('UPDATE_ADDRESS'));
    return (React.createElement("section", { className: "k-account--section" },
        React.createElement("header", null,
            React.createElement("h1", null,
                React.createElement(FormattedMessage, { id: "Vos adresses", defaultMessage: "Vos adresses" })),
            addresses.length > 1 ?
                React.createElement("p", null,
                    React.createElement(FormattedMessage, { id: "Vous n'avez pas enregistr\u00E9 d'adresses, ajoutez-en \u00E0 l'aide du bouton ci-dessous", defaultMessage: "Vous n'avez pas enregistr\u00E9 d'adresses, ajoutez-en \u00E0 l'aide du bouton ci-dessous" }),
                    ".")
                :
                    React.createElement("p", null,
                        React.createElement(FormattedMessage, { id: "Liste des adresses enregistr\u00E9es, ajoutez-en \u00E0 l'aide du bouton ci-dessous", defaultMessage: "Liste des adresses enregistr\u00E9es, ajoutez-en \u00E0 l'aide du bouton ci-dessous" }),
                        ".")),
        React.createElement("div", { className: "adresses container" },
            React.createElement("div", { className: `adresse-item ${activeStep === '' ? 'open' : ''}`, onClick: () => setActiveStep('') },
                React.createElement("div", { className: "adresse-toggle" },
                    React.createElement(Button, { onClick: () => setActiveStep(''), className: "k-btn", buttonName: intl.formatMessage({ id: "Ajouter une nouvelle adresse", defaultMessage: "Ajouter une nouvelle adresse" }) })),
                React.createElement("div", { className: "adresse-infos" },
                    React.createElement(Formik, { validateOnChange: true, initialValues: initialValues, enableReinitialize: true, onSubmit: async (values, { resetForm }) => {
                            dispatch(createAddressAttempt(values, (err, res) => {
                                if (res) {
                                    setActiveStep(res._id);
                                    resetForm({ values: initialValues });
                                }
                            }));
                        } },
                        React.createElement(AddressForm, { apiErrors: apiErrors },
                            React.createElement("div", { className: "adresse-actions" },
                                React.createElement(Button, { buttonName: intl.formatMessage({ id: "Enregistrer l'adresse", defaultMessage: "Enregistrer l'adresse" }), className: "k-btn" })))))),
            addresses.length > 0 && addresses.map((address) => {
                return React.createElement("div", { key: address._id, className: `adresse-item ${activeStep === address._id ? 'open' : ''}` },
                    React.createElement("div", { onClick: () => setActiveStep(address._id), className: "adresse-toggle" },
                        React.createElement("strong", null,
                            "Adresse : ",
                            address.name),
                        React.createElement(Angle, { isActive: activeStep === address._id, setActiveStep: setActiveStep, step: address._id })),
                    React.createElement("div", { className: "adresse-infos" },
                        React.createElement(Formik, { validateOnChange: true, initialValues: address, enableReinitialize: true, onSubmit: async (values) => dispatch(updateAddressAttempt(values)) },
                            React.createElement(AddressForm, { apiErrors: apiErrors },
                                React.createElement("div", { className: "adresse-actions" },
                                    React.createElement(Button, { type: "button", className: "k-btn", onClick: () => dispatch(deleteAddressAttempt(address._id)), buttonName: intl.formatMessage({ id: "Supprimer", defaultMessage: "Supprimer" }) }),
                                    React.createElement(Button, { loading: pending, buttonName: intl.formatMessage({ id: "Modifier", defaultMessage: "Modifier" }), className: "k-btn" }))))));
            })),
        React.createElement(CheckMarkAnimation, { message: intl.formatMessage({ id: "La modification de votre adresse a bien été enregistrée", defaultMessage: "La modification de votre adresse a bien été enregistrée." }), isActive: addressUpdated })));
}
export default Adresses;
