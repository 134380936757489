import React, { useState } from "react";
import ContentLoader from 'react-content-loader';
import "./Img.scss";
// Proto
function Img(props) {
    const [loaded, setLoaded] = useState(false);
    return (React.createElement("div", { className: "image", onContextMenu: (e) => e.preventDefault() },
        React.createElement(ContentLoader, Object.assign({ speed: 2, style: !loaded ? {} : { display: 'none' }, uniqueKey: props.src, backgroundColor: "transparent", foregroundColor: "transparent" }, props)),
        React.createElement("img", { src: props.src, onLoad: () => setLoaded(true), width: props.width, title: props.name + ' | KUST, La Marque des Coiffeurs pas Comme Les Autres', alt: props.alt, height: props.height })));
}
export default Img;
