import { Summary } from "components/Cart/Summary";
import React, { createContext, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectCart } from "store/cart/cartSelectors";
import { OrderSteps } from "./OrderSteps";
import { useIntl } from "react-intl";
export const OrderContext = createContext({});
function Order() {
    const ref = useRef(null);
    const intl = useIntl();
    const [order, setOrder] = useState({
        billingAddress: null,
        shippingAddress: null,
        shippingInfos: null,
        paymentMode: null
    });
    const setOrderValue = (name, value) => {
        setOrder((prev) => (Object.assign(Object.assign({}, prev), { [name]: value })));
    };
    const cart = useSelector(selectCart);
    return (React.createElement(OrderContext.Provider, { value: ref },
        React.createElement("div", { className: "k-cart k-cart--order" },
            React.createElement("div", { className: "k-cart--breadcrumb" },
                React.createElement("ol", { className: "k-topbar--items" },
                    React.createElement("li", { className: "k-topbar--item" },
                        React.createElement(Link, { className: "k-topbar--label", "data-text": "Accueil", to: "/" }, "Accueil")),
                    React.createElement("li", { className: "k-topbar--item" },
                        React.createElement(Link, { className: "k-topbar--label", "data-text": "Produits", to: "/nos-produits" }, "Produits")),
                    React.createElement("li", { className: "k-topbar--item" },
                        React.createElement(Link, { className: "k-topbar--label", "data-text": "Panier", to: "/panier" }, "Panier")),
                    React.createElement("li", { className: "k-topbar--item" },
                        React.createElement(Link, { className: "k-topbar--label", "data-text": "Commande", to: "/commande" }, "Commande")))),
            React.createElement("div", { className: "k-cart--container" },
                React.createElement("section", { className: "k-cart--content" },
                    React.createElement(OrderSteps, { order: order, setOrderValue: setOrderValue })),
                React.createElement("div", { className: "k-cart--aside" },
                    React.createElement(Summary, { title: intl.formatMessage({ id: "Récapitulatif", defaultMessage: "Récapitulatif" }), cart: cart }))))));
}
export default Order;
