import { ShippingModes, convertToEuros, BusinessType } from "common";
import { Field } from "formik";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { selectProfileInfos } from "store/account/profile/profileSelectors";
import { useIntl } from "react-intl";
export function ShippingMode({ fees }) {
    const intl = useIntl();
    const SHIPPING_MODES_B2B = [
        {
            mode: ShippingModes.CLASSIC,
            description: intl.formatMessage({ id: 'Livraison en salon 24 à 48H', defaultMessage: 'Livraison en salon 24 à 48H' })
        },
        {
            mode: ShippingModes.PREDICT,
            description: intl.formatMessage({ id: 'Livraison à domicile 24 à 48H', defaultMessage: 'Livraison à domicile 24 à 48H' })
        },
        {
            mode: ShippingModes.PICKUP,
            description: intl.formatMessage({ id: 'Livraison en point relais', defaultMessage: 'Livraison en point relais' })
        },
    ];
    const SHIPPING_MODES_B2C = [
        {
            mode: ShippingModes.B2CPREDICT,
            description: intl.formatMessage({ id: 'Livraison à domicile 24 à 48H', defaultMessage: 'Livraison à domicile 24 à 48H' })
        },
        {
            mode: ShippingModes.B2CPICKUP,
            description: intl.formatMessage({ id: 'Livraison en point relais', defaultMessage: 'Livraison en point relais' })
        },
    ];
    const profile = useSelector(selectProfileInfos);
    const shippingMode = (profile === null || profile === void 0 ? void 0 : profile.businessType) === BusinessType.B2B ? SHIPPING_MODES_B2B : SHIPPING_MODES_B2C;
    return (React.createElement(React.Fragment, null,
        React.createElement(Field, { name: "mode" }, ({ form }) => (React.createElement("div", { className: "billings" }, shippingMode.map((value) => (React.createElement(Fragment, { key: value.mode }, fees[value.mode] !== null && React.createElement(React.Fragment, null,
            React.createElement("div", { className: "billing-item" },
                React.createElement("label", { className: "radio radio-before", htmlFor: value.mode },
                    React.createElement("span", { className: "radio__input" },
                        React.createElement("input", { id: value.mode, name: "shipping_mode", type: "radio", onClick: () => form.setFieldValue('mode', value.mode) }),
                        React.createElement("span", { className: "radio__control" })),
                    React.createElement("strong", null,
                        value.description,
                        " (",
                        convertToEuros(fees[value.mode]),
                        "\u20AC)"))))))))))));
}
