import { selectErrors } from "common";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDropdown } from "shared/hooks/useDropdown";
import { fetchTagsAttempt, setActiveTag } from "store/tags/tagActions";
import { selectTags } from "store/tags/tagSelectors";
import { ProductFilter } from "../components/ProductFilter";
import "./ProductsFilters.scss";
import { Link, useParams } from "react-router-dom";
export function ProductsFilters() {
    const dispatch = useDispatch();
    const { dropdownRef, dropdownActive, setDropdownActive } = useDropdown();
    const apiErrors = useSelector(selectErrors('FETCH_TAGS'));
    const tags = useSelector(selectTags).filter((tag) => !!tag.isDisplayable);
    useEffect(() => {
        dispatch(fetchTagsAttempt());
    }, [dispatch]);
    const { tag } = useParams();
    const toggleTag = (tagId = '') => {
        dispatch(setActiveTag(tagId));
        setDropdownActive(false);
    };
    function slugify(text) {
        const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
        const to = "aaaaaeeeeeiiiiooooouuuunc------";
        const newText = text.split('').map((letter, i) => letter.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i)));
        return newText
            .toString()
            .toLowerCase()
            .trim()
            .replace('#', '-')
            .replace(/\s+/g, '-')
            .replace(/&/g, '-y-')
            .replace(/[^\w\-]+/g, '')
            .replace(/\-\-+/g, '-');
    }
    return (React.createElement("div", { ref: dropdownRef, className: `k-filters ${dropdownActive ? 'is-active' : ''}` },
        React.createElement("div", { className: "k-topbar--items k-filters--items dropdown-menu", id: "dropdown-menu4", role: "menu" },
            React.createElement("div", { className: `k-topbar--item k-filters--item dropdown-item ${!tag ? 'active' : ''}` },
                React.createElement(Link, { to: '/nos-produits' },
                    React.createElement(ProductFilter, { onClick: () => toggleTag(), defaultChecked: true, label: "Tous" }))),
            tags.map((tagItem) => {
                const tagSlug = slugify(tagItem.name);
                return (React.createElement("div", { key: tagItem._id, className: `k-filters--item dropdown-item ${tagSlug === tag ? 'active' : ''}` },
                    React.createElement(Link, { to: `/nos-produits/categorie/` + tagSlug },
                        React.createElement(ProductFilter, { onClick: () => toggleTag(tagItem._id), label: tagItem.name }))));
            }))));
}
