import { push } from "connected-react-router";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { selectCart } from "store/cart/cartSelectors";
import { FormattedMessage, useIntl } from "react-intl";
export function QuotationFailure() {
    const dispatch = useDispatch();
    const intl = useIntl();
    const cart = useSelector(selectCart);
    useEffect(() => {
        if (!cart)
            dispatch(push('/'));
    }, [cart]);
    return (React.createElement("div", { className: "k-cart" },
        React.createElement(Helmet, null,
            React.createElement("title", null, "KUST | La Marque des Coiffeurs pas Comme Les Autres")),
        React.createElement("div", { className: "k-cart--breadcrumb" },
            React.createElement("ol", { className: "k-topbar--items" },
                React.createElement("li", { className: "k-topbar--item" },
                    React.createElement(Link, { className: "k-topbar--label", "data-text": "Accueil", to: "/" }, "Accueil")),
                React.createElement("li", { className: "k-topbar--item" },
                    React.createElement(Link, { className: "k-topbar--label", "data-text": "Produits", to: "/nos-produits" }, "Produits")),
                React.createElement("li", { className: "k-topbar--item" },
                    React.createElement(Link, { className: "k-topbar--label", "data-text": "Panier", to: "/panier" }, "Panier")),
                React.createElement("li", { className: "k-topbar--item" },
                    React.createElement(Link, { className: "k-topbar--label", "data-text": "Devis", to: "/devis" }, "Devis")))),
        React.createElement("div", { className: "k-cart--container" },
            React.createElement("div", { className: "k-cart--content" },
                React.createElement("div", { className: "k-cart--content_text" },
                    React.createElement("h1", null,
                        React.createElement(FormattedMessage, { id: "Une erreur s'est produite !", defaultMessage: "Une erreur s'est produite !" })),
                    React.createElement("h2", null,
                        React.createElement(FormattedMessage, { id: "Le devis n'est pas enregistr\u00E9", defaultMessage: "Le devis n'est pas enregistr\u00E9" }),
                        "."),
                    React.createElement(Link, { to: "/devis", className: "k-cta k-cta--polygonal" },
                        React.createElement(FormattedMessage, { id: "Essayer \u00E0 nouveau", defaultMessage: "Essayer \u00E0 nouveau" })))))));
}
