import React, { useState } from "react";
import "./Auth.scss";
import Register from '../Register/Register';
import Login from '../Login/Login';
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";
function Auth() {
    const [isLoginSelected, setIsLoginSelected] = useState(true);
    return (React.createElement("div", { className: "k-auth" },
        React.createElement(Helmet, null,
            React.createElement("title", null, "Inscription & Connexion - KUST | La Marque des Coiffeurs pas Comme Les Autres\"")),
        React.createElement("div", { className: "k-auth--container" },
            React.createElement("header", { className: "k-auth--header" },
                React.createElement("h1", null,
                    React.createElement(FormattedMessage, { id: "Rejoindre Kust", defaultMessage: "Rejoindre Kust" })),
                React.createElement("p", null,
                    React.createElement(FormattedMessage, { id: "Connectez-vous ou cr\u00E9ez votre compte professionnel et rejoignez notre communaut\u00E9.", defaultMessage: "Connectez-vous ou cr\u00E9ez votre compte professionnel et rejoignez notre communaut\u00E9." }))),
            React.createElement("div", { className: "k-auth--toggle" },
                React.createElement("button", { onClick: () => setIsLoginSelected(true), className: isLoginSelected ? 'active' : '' }, "D\u00E9j\u00E0 client"),
                React.createElement("button", { onClick: () => setIsLoginSelected(false), className: !isLoginSelected ? 'active' : '' }, "Inscription")),
            React.createElement("div", { className: "k-auth--content" }, isLoginSelected ? React.createElement(Login, null) : React.createElement(Register, null)))));
}
export default Auth;
