import { selectPending, selectErrors, selectLastError, selectIsAuthenticated } from "common";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { verifyCartPromotionAttempt, decrementProductQuantityCart, incrementProductQuantityCart, prevalidateCartAttempt, removeFromCart, fetchUserPromotionAttempt, togglePromotionOpen, resetCart, selectProductToModify, prevalidateCartToQuotationAttempt } from "store/cart/cartActions";
import { selectCart, selectIsPromoOpen } from "store/cart/cartSelectors";
import CartItem from "./components/CartItem/CartItem";
import { CartInfos } from "components/Cart/CartInfos";
import "./Cart.scss";
import { Button, InputError } from "components/Form";
import CartPromo from "./components/CartPromo/CartPromo";
import { Helmet } from "react-helmet";
import { reduceCartProduct } from "services/utils/product.mapper";
import { selectProfileInfos } from "store/account/profile/profileSelectors";
import { FormattedMessage, useIntl } from "react-intl";
function Cart() {
    const dispatch = useDispatch();
    const intl = useIntl();
    const cart = useSelector(selectCart);
    const [cartOpen, setCartOpen] = useState(true);
    const apiError = useSelector(selectLastError('PREVALIDATE_CART'));
    const pending = useSelector(selectPending('PREVALIDATE_CART'));
    const promoApiErrors = useSelector(selectErrors('VERIFY_CART_PROMOTION'));
    const promoPending = useSelector(selectPending('VERIFY_CART_PROMOTION'));
    const isPromoOpen = useSelector(selectIsPromoOpen);
    const isAuthenticated = useSelector(selectIsAuthenticated());
    const profile = useSelector(selectProfileInfos);
    const prevalidate = () => {
        dispatch(prevalidateCartAttempt(cart, profile === null || profile === void 0 ? void 0 : profile.businessType));
    };
    const prevalidateToQuotation = () => {
        dispatch(prevalidateCartToQuotationAttempt(cart, profile === null || profile === void 0 ? void 0 : profile.businessType));
    };
    const verifyPromotion = ({ code }) => {
        dispatch(verifyCartPromotionAttempt(code, cart));
    };
    const togglePromo = (open) => {
        dispatch(togglePromotionOpen(open));
    };
    const emptyCart = () => {
        dispatch(resetCart());
    };
    useEffect(() => {
        if (cart) {
            dispatch(fetchUserPromotionAttempt(cart));
        }
    }, [cart.products]);
    return (React.createElement("div", { className: "k-cart" },
        React.createElement(Helmet, null,
            React.createElement("title", null, "Panier - KUST | La Marque des Coiffeurs pas Comme Les Autres")),
        React.createElement("div", { className: "k-cart--breadcrumb" },
            React.createElement("ol", { className: "k-topbar--items" },
                React.createElement("li", { className: "k-topbar--item" },
                    React.createElement(Link, { className: "k-topbar--label", "data-text": "Accueil", to: "/" }, "Accueil")),
                React.createElement("li", { className: "k-topbar--item" },
                    React.createElement(Link, { className: "k-topbar--label", "data-text": "Produits", to: "/nos-produits" }, "Produits")),
                React.createElement("li", { className: "k-topbar--item" },
                    React.createElement(Link, { className: "k-topbar--label", "data-text": "Panier", to: "/panier" }, "Panier")))),
        React.createElement("div", { className: "k-cart--container" },
            React.createElement("section", { className: "k-cart--content" }, cart.products.length
                ?
                    React.createElement("ul", { className: "k-cart--items" }, cart.products.map((product, index) => {
                        return (React.createElement("li", { key: index },
                            React.createElement(CartItem, { editable: true, increment: () => dispatch(incrementProductQuantityCart(product)), decrement: () => dispatch(decrementProductQuantityCart(product)), remove: () => dispatch(removeFromCart(product)), product: reduceCartProduct(product), findIndex: () => dispatch(selectProductToModify(index)) })));
                    }))
                :
                    React.createElement("div", { className: "k-cart--content_text" },
                        React.createElement("h1", null,
                            React.createElement(FormattedMessage, { id: "Votre panier est vide.", defaultMessage: "Votre panier est vide." })),
                        React.createElement(Link, { to: "/nos-produits", className: "k-cta k-cta--polygonal" },
                            React.createElement(FormattedMessage, { id: "Voir nos produits", defaultMessage: "Voir nos produits" })))),
            React.createElement("aside", { className: "k-cart--aside" }, cart.products.length > 0 ?
                React.createElement(React.Fragment, null, !isPromoOpen && isAuthenticated
                    ?
                        React.createElement(React.Fragment, null,
                            React.createElement("div", { className: `${!cartOpen ? "is-closed" : ""}` },
                                React.createElement(CartInfos, { cart: cart },
                                    React.createElement(Button, { loading: pending, disabled: !(cart.total > 0), type: "button", onClick: prevalidate, buttonName: intl.formatMessage({ id: "Passer commande", defaultMessage: "Passer commande" }), className: "k-cta k-cta--polygonal" }),
                                    React.createElement(InputError, { apiErrors: apiError }),
                                    React.createElement("div", { className: "k-cart--aside_actions" },
                                        React.createElement(Button, { loading: pending, disabled: !(cart.total > 0), type: "button", onClick: prevalidateToQuotation, buttonName: intl.formatMessage({ id: "Sauvegarder le panier", defaultMessage: "Sauvegarder le panier" }), className: "k-lk--icon icon-save" }),
                                        React.createElement(Button, { onClick: () => togglePromo(true), buttonName: intl.formatMessage({ id: "Appliquer un code promo", defaultMessage: "Appliquer un code promo" }), className: "k-lk--icon icon-gift" }),
                                        React.createElement(Button, { type: "button", onClick: emptyCart, buttonName: intl.formatMessage({ id: "Vider le panier",
                                                defaultMessage: "Vider le panier" }), className: "k-lk--icon icon-bin" })))))
                    :
                        React.createElement("div", { className: "" },
                            React.createElement(CartPromo, { apiErrors: promoApiErrors, onSubmit: verifyPromotion, togglePromo: togglePromo })))
                :
                    React.createElement(React.Fragment, null))),
        React.createElement("div", { className: "columns is-multiline is-mobile is-centered" })));
}
export default Cart;
