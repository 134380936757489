import React, { useEffect } from 'react';
import "./Home.scss";
import { Link, useParams } from "react-router-dom";
import Profile from '../Profile/Profile';
import Commandes from '../Commandes/Commandes';
import Adresses from '../Adresses/Adresses';
import Videos from '../Videos/Videos';
import Formations from '../Formations/Formations';
import Documents from '../Documents/Documents';
import { useDispatch, useSelector } from 'react-redux';
import { logoutAttempt } from 'common';
import { useDropdown } from 'shared/hooks/useDropdown';
import { selectProfileInfos } from 'store/account/profile/profileSelectors';
import { fetchDocumentsAttempt } from 'store/account/documents/documentActions';
import { selectDocument } from 'store/account/documents/documentSelectors';
import { fetchOrdersAttempt } from 'store/account/orders/ordersActions';
import { selectOrders } from 'store/account/orders/ordersSelectors';
import Devis from '../Devis/Devis';
import Factures from '../Factures/Factures';
import Credits from '../Credits/Credits';
import { selectCredits } from 'store/account/credits/creditsSelectors';
import { fetchCreditsAttempt } from 'store/account/credits/creditsActions';
import { FormattedMessage } from "react-intl";
var Pages;
(function (Pages) {
    Pages["COMMANDS"] = "commandes";
    Pages["BILLS"] = "factures";
    Pages["VIDEOS"] = "videos";
    Pages["TRAININGS"] = "formations";
    Pages["DOCUMENTS"] = "documents";
    Pages["ADDRESSES"] = "adresses";
    Pages["PROFILE"] = "profile";
    Pages["QUOTATIONS"] = "devis";
    Pages["CREDITS"] = "credits";
})(Pages || (Pages = {}));
function Home() {
    const { page } = useParams();
    const { dropdownRef, dropdownActive, setDropdownActive } = useDropdown();
    const dispatch = useDispatch();
    const isPageActive = (value) => {
        return value === page ? 'active' : '';
    };
    const profile = useSelector(selectProfileInfos);
    const documents = useSelector(selectDocument);
    const orders = useSelector(selectOrders);
    const credits = useSelector(selectCredits);
    const showCurrentPage = () => {
        switch (page) {
            case Pages.COMMANDS:
                return React.createElement(Commandes, null);
            case Pages.BILLS:
                return React.createElement(Factures, null);
            case Pages.VIDEOS:
                return React.createElement(Videos, null);
            case Pages.TRAININGS:
                return React.createElement(Formations, null);
            case Pages.DOCUMENTS:
                return React.createElement(Documents, null);
            case Pages.ADDRESSES:
                return React.createElement(Adresses, null);
            case Pages.PROFILE:
                return React.createElement(Profile, null);
            case Pages.QUOTATIONS:
                return React.createElement(Devis, null);
            case Pages.CREDITS:
                return React.createElement(Credits, null);
            default:
                return;
        }
    };
    useEffect(() => {
        dispatch(fetchDocumentsAttempt());
        dispatch(fetchOrdersAttempt());
        dispatch(fetchCreditsAttempt());
    }, [dispatch]);
    return (React.createElement("div", { className: "k-account" },
        React.createElement("div", { className: "k-account--nav" },
            React.createElement("div", { className: "k-topbar--items" },
                React.createElement("div", { className: "k-topbar--item" },
                    React.createElement(Link, { to: "/mon-compte/profile", "data-text": "Profil", className: `k-topbar--label ${isPageActive(Pages.PROFILE)}` },
                        React.createElement(FormattedMessage, { id: "Profil", defaultMessage: "Profil" }))),
                React.createElement("div", { className: "k-topbar--item" },
                    React.createElement(Link, { to: "/mon-compte/commandes", "data-text": "Commandes", className: `k-topbar--label ${isPageActive(Pages.COMMANDS)}` },
                        React.createElement(FormattedMessage, { id: "Commandes", defaultMessage: "Commandes" }))),
                React.createElement("div", { className: "k-topbar--item" },
                    React.createElement(Link, { to: "/mon-compte/devis", "data-text": "Paniers", className: `k-topbar--label ${isPageActive(Pages.QUOTATIONS)}` },
                        React.createElement(FormattedMessage, { id: "Paniers", defaultMessage: "Paniers" }))),
                React.createElement("div", { className: "k-topbar--item" },
                    React.createElement(Link, { to: "/mon-compte/adresses", "data-text": "Adresses", className: `k-topbar--label ${isPageActive(Pages.ADDRESSES)}` },
                        React.createElement(FormattedMessage, { id: "Adresses", defaultMessage: "Adresses" }))),
                React.createElement("div", { className: "k-topbar--item" },
                    React.createElement(Link, { to: "/mon-compte/videos", "data-text": "Vid\u00E9os", className: `k-topbar--label ${isPageActive(Pages.VIDEOS)}` },
                        React.createElement(FormattedMessage, { id: "Vid\u00E9os", defaultMessage: "Vid\u00E9os" }))),
                React.createElement("div", { className: "k-topbar--item" },
                    React.createElement(Link, { to: "/mon-compte/formations", "data-text": "Formations", className: `k-topbar--label ${isPageActive(Pages.TRAININGS)}` },
                        React.createElement(FormattedMessage, { id: "Formations", defaultMessage: "Formations" }))),
                React.createElement("div", { className: "k-topbar--item" },
                    React.createElement(Link, { to: "/mon-compte/documents", "data-text": "Documents", className: `k-topbar--label ${isPageActive(Pages.DOCUMENTS)}` },
                        React.createElement(FormattedMessage, { id: "Documents", defaultMessage: "Documents" }))),
                React.createElement("div", { className: "k-topbar--item" },
                    React.createElement(Link, { to: "/mon-compte/credits", "data-text": "Avoirs", className: `k-topbar--label ${isPageActive(Pages.CREDITS)}` },
                        React.createElement(FormattedMessage, { id: "Avoirs", defaultMessage: "Avoirs" }))),
                React.createElement("div", { className: "k-topbar--item" },
                    React.createElement("button", { onClick: () => dispatch(logoutAttempt()) },
                        React.createElement(FormattedMessage, { id: "D\u00E9connexion", defaultMessage: "D\u00E9connexion" }))))),
        React.createElement("div", { className: "k-account--content" }, showCurrentPage())));
}
export default Home;
