import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { downloadDocumentAttempt } from 'store/account/documents/documentActions';
import { selectDocument } from 'store/account/documents/documentSelectors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage, useIntl } from "react-intl";
import "./Documents.scss";
function Documents() {
    const dispatch = useDispatch();
    const intl = useIntl();
    const documents = useSelector(selectDocument);
    return (React.createElement("section", { className: "k-account--section" },
        React.createElement("header", null,
            React.createElement("h1", null,
                React.createElement(FormattedMessage, { id: "Vos documents", defaultMessage: "Vos documents" })),
            (documents === null || documents === void 0 ? void 0 : documents.length) === 0 ?
                React.createElement("p", null,
                    React.createElement(FormattedMessage, { id: "Il n'y a pas de documents disponibles pour le moment", defaultMessage: "Il n'y a pas de documents disponibles pour le moment" }),
                    ".")
                :
                    React.createElement("p", null,
                        React.createElement(FormattedMessage, { id: "Liste des documents \u00E0 votre disposition", defaultMessage: "Liste des documents \u00E0 votre disposition" }),
                        ".")),
        React.createElement("div", { className: "documents container" }, (documents === null || documents === void 0 ? void 0 : documents.length) > 0 &&
            React.createElement("div", null, documents.map((document) => React.createElement("div", { className: "document-item", key: document === null || document === void 0 ? void 0 : document._id },
                React.createElement("div", null,
                    React.createElement("strong", null, document.name),
                    React.createElement("p", null, document.description),
                    React.createElement("p", { className: "format" },
                        React.createElement(FormattedMessage, { id: "Format pdf", defaultMessage: "Format .pdf" }))),
                React.createElement("div", null,
                    React.createElement("button", { onClick: () => dispatch(downloadDocumentAttempt(document._id)) },
                        React.createElement(FormattedMessage, { id: "Voir", defaultMessage: "Voir" }),
                        " ",
                        React.createElement(FontAwesomeIcon, { icon: faEye })))))))));
}
export default Documents;
