import { Button } from "components/Form";
import React, { useContext } from "react";
import { OrderContext } from "../Order";
import { useIntl } from "react-intl";
export function StepButton({ title = "Etape suivante", hidden = false, loading, order, disabled, onClick }) {
    const context = useContext(OrderContext);
    const intl = useIntl();
    const getButton = () => {
        return !hidden && React.createElement(Button, { type: "button", className: order ? "k-cta k-cta--polygonal" : "k-cta k-cta--step", loading: loading, disabled: disabled, onClick: onClick, buttonName: title });
    };
    return (React.createElement(React.Fragment, null, getButton()));
}
