import { ProductActionTypes } from './productTypes';
const initialState = {
    list: [],
    colorChart: null,
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ProductActionTypes.FETCH_PRODUCTS_SUCCESS:
            return Object.assign(Object.assign({}, state), { list: action.payload });
        case ProductActionTypes.FETCH_PRODUCT_SUCCESS:
            // console.log(state)
            return Object.assign(Object.assign({}, state), { list: [
                    ...((state === null || state === void 0 ? void 0 : state.list) || [])
                        .filter(product => product._id !== action.payload._id)
                        .map(product => product),
                    action.payload
                ] });
        case ProductActionTypes.FETCH_COLOR_CHART_SUCCESS:
            return Object.assign(Object.assign({}, state), { colorChart: action.payload });
        default:
            return state;
    }
};
export { reducer as productReducer };
