import { Button, Input, InputError } from "components/Form";
import { Form, Formik } from "formik";
import React from "react";
import './CartPromo.scss';
import { FormattedMessage, useIntl } from "react-intl";
function CartPromo({ onSubmit, apiErrors, togglePromo }) {
    const intl = useIntl();
    return (React.createElement(Formik, { validateOnChange: true, initialValues: {
            code: '',
        }, enableReinitialize: true, onSubmit: onSubmit },
        React.createElement(Form, { noValidate: true, autoComplete: 'off', className: "k-form k-form--white" },
            React.createElement("div", { className: "k-cart--aside_data" },
                React.createElement("strong", null,
                    React.createElement(FormattedMessage, { id: "Code promo", defaultMessage: "Code promo" }))),
            React.createElement(Input, { name: "code", placeholder: intl.formatMessage({ id: 'Votre code', defaultMessage: 'Votre code' }) + '...' }),
            React.createElement(InputError, { apiErrors: (apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.code) || (apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.message) }),
            React.createElement(Button, { type: "submit", buttonName: intl.formatMessage({ id: "Valider", defaultMessage: "Valider" }), className: "k-cta k-cta--polygonal" }),
            React.createElement("div", { className: "k-cart--aside_actions" },
                React.createElement(Button, { type: "button", onClick: () => togglePromo(false), buttonName: intl.formatMessage({ id: "Annuler et revenir au panier", defaultMessage: "Annuler et revenir au panier" }), className: "k-lk--icon icon-bin" })))));
}
export default CartPromo;
