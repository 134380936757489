import React from "react";
import { Step } from "./Step";
import "./AddressStep.scss";
import { AddressItem } from "./AddressItem";
export function AddressStep({ name, title, description, disableCurrentStep, selectAddress, addresses, step, activeStep, activeOrderStep, setActiveOrderStep, children, }) {
    return (React.createElement(React.Fragment, null,
        React.createElement(Step, { step: step, disabled: disableCurrentStep, activeStep: activeOrderStep, setActiveStep: setActiveOrderStep, title: title, description: description },
            React.createElement("div", null,
                children[0],
                addresses.map((address) => {
                    return React.createElement("div", { key: address._id, className: "billings" },
                        React.createElement(AddressItem, { activeStep: activeStep, selectAddress: selectAddress, address: address, name: name }));
                })),
            children[1])));
}
