var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Field } from "formik";
import React from "react";
function InputDate(_a) {
    var { name, label, color, rules } = _a, rest = __rest(_a, ["name", "label", "color", "rules"]);
    return (React.createElement(Field, { name: name }, ({ form, field }) => (React.createElement("label", Object.assign({ className: "k-input input-label" }, rest),
        React.createElement("span", null, label),
        React.createElement("input", Object.assign({ maxLength: "10", onChange: (e) => {
                let formatBirthday = e.target.value;
                function checkValue(str, max) {
                    if (str.charAt(0) !== '0' || str === '00') {
                        let num = parseInt(str, 0);
                        if (isNaN(num) || num <= 0 || num > max) {
                            num = 1;
                        }
                        str = num > parseInt(max.toString().charAt(0), 0) && num.toString().length === 1 ? '0' + num : num.toString();
                    }
                    return str;
                }
                if (/\D\/$/.test(formatBirthday)) {
                    formatBirthday = formatBirthday.substr(0, formatBirthday.length - 3);
                }
                const values = formatBirthday.split('/').map((v) => {
                    return v.replace(/\D/g, '');
                });
                if (values[0]) {
                    values[0] = checkValue(values[0], 31);
                }
                if (values[1]) {
                    values[1] = checkValue(values[1], 12);
                }
                const output = values.map((v, i) => {
                    return v.length === 2 && i < 2 ? v + '/' : v;
                });
                form.setFieldValue(name, output.join('').substr(0, 14));
            }, placeholder: "JJ/MM/AAAA", type: "text", value: field.value, name: name, className: "k-input--input" }, rest))))));
}
export default InputDate;
