import Img from "components/Img/Img";
import React from "react";
import { Link } from 'react-router-dom';
import "./ProductListItem.scss";
import { truncate } from "shared/helpers";
import { ReviewStars } from "components/Review/ReviewStars";
import { FormattedMessage, useIntl } from "react-intl";
function removeTags(html) {
    var temp = document.createElement("div");
    temp.innerHTML = html;
    return temp.textContent || temp.innerText || "";
}
function ProductListItem({ product, path, framed }) {
    const intl = useIntl();
    return (React.createElement(Link, { className: `${framed ? 'framed' : ''} product-item k-listitem`, to: path },
        React.createElement("div", { className: "product-item-image k-listitem--image" }, !!product.imageUrl &&
            React.createElement(Img, { src: `${product === null || product === void 0 ? void 0 : product.imageUrl[0]}`, name: `${product === null || product === void 0 ? void 0 : product.name}`, alt: `${product === null || product === void 0 ? void 0 : product.name}` })),
        React.createElement(ReviewStars, { reviewId: product.reviewId }),
        React.createElement("h1", { className: "k-listitem--title" },
            React.createElement(FormattedMessage, { id: product.name, defaultMessage: product.name })),
        React.createElement("p", { className: "k-listitem--text" }, truncate(removeTags(product.description), 15))));
}
export default ProductListItem;
